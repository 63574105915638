import React from "react"

export const H1 = ({ children, className = "" }) => {
  return (
    <h1
      className={`${className} text-5xl md:text-4xl font-bold text-secondary`}
    >
      {children}
    </h1>
  )
}

export const H2 = ({ children, className = "" }) => {
  return (
    <h2 className={`${className} text-xl md:text-lg font-light`}>{children}</h2>
  )
}

const Headings = { H1, H2 }

export default Headings
