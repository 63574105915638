import React, { useState } from "react"
import Button from "../../components/elements/Button"
import { H1, H2 } from "../../components/elements/Headings"
import Input from "../../components/elements/Input"
import {
  PageTitleContainer,
  ContentContainer,
} from "../../components/layout/Containers"
import Layout from "../../components/layout/Layout"
import SEO from "../../components/seo"

function DifferenceInDays() {
  const [dateStart, setDateStart] = useState<Date>(null)
  const [dateEnd, setDateEnd] = useState<Date>(null)
  const [difference, setDifference] = useState<number>(null)

  function calculate() {
    if (dateEnd != null && dateStart != null) {
      let diff = Math.abs(dateEnd.getTime() - dateStart.getTime())
      let diffDays: number = Math.ceil(diff / (1000 * 3600 * 24))
      setDifference(diffDays)
    }
  }

  function reset() {
    setDateStart(null)
    setDateEnd(null)
    setDifference(null)
  }

  return (
    <>
      <SEO
        title="Difference in Days"
        description="Difference in Days between two dates"
      />
      <Layout>
        <PageTitleContainer>
          <H1>Difference in Days</H1>
          <H2>Select two dates and then press the button!</H2>
        </PageTitleContainer>
        <ContentContainer className="flex justify-between">
          <div>
            <Input
              className="w-64 mr-4"
              placeholder="Starting date..."
              onChange={$event => setDateStart(new Date($event.substr(0, 10)))}
              type="date"
              value={dateStart ? dateStart.toISOString().substr(0, 10) : ""}
            />
            <Input
              className="w-64"
              placeholder="Date to convert..."
              onChange={$event => setDateEnd(new Date($event.substr(0, 10)))}
              type="date"
              value={dateEnd ? dateEnd.toISOString().substr(0, 10) : ""}
            />
          </div>
          <div className="flex">
            <Button click={calculate} primary>
              Calculate
            </Button>
            <Button click={reset} accent>
              Reset
            </Button>
          </div>
        </ContentContainer>
        {difference != null && (
          <ContentContainer className="mt-8">
            The difference in days is <strong>{difference}</strong>
          </ContentContainer>
        )}
      </Layout>
    </>
  )
}

export default DifferenceInDays
